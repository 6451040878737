// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Arun Soorya",
  middleName: "",
  lastName: "K",
  message: " Passionate about changing the world with technology. ",
  icons: [
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/arunsooryak/",
    },
    {
      image: "fa-stack-overflow",
      url: "https://stackoverflow.com/users/12929660/arun-soorya",
    },
    {
      image: "fa-github",
      url: "https://github.com/Soorya018",
    },
    
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/lost___lllusion/",
    },
    
    {
      image: "fa-twitter",
      url: "https://twitter.com/Arun__Soorya",
    },
     
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/arunsoorya.jpg"),
  imageSize: 375,
  message:
    "Results-focused customer analyst with a history of transforming data into actionable insights to boost profitability. Proficient in strategic decision-making and driven by innovation. Looking to further refine analytical abilities and leadership skills to advance business acumen.",
  resume: "https://drive.google.com/file/d/171Z9-OvL6MiR8INHb9JowmS9DGaf-XXs/view?usp=sharing",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "Soorya018", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: ["Cardio-Disease-Predictor","Airline_Ticket_Pricing_Prediction","Face-Attendance","Flask-Application"],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/arunsoorya.jpg"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/arunsoorya.jpg"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Programming: Python, Java, Deluge, JavaScript, Golang, R", value: 90 },
    { name: "Data Visualization Tools: PowerBI, Tableau, Zoho Analytics", value: 75 },
    { name: "OS: Windows Servers, Linux ((Ubuntu, RHEL))", value: 85 },
    { name: "Databases: MSSQL, PostgreSQL, MongoDB", value: 90 },
    { name: "Protocols: SAML, IMAPS, SMTP, DHCP, SNMP, DNS", value: 80 },
    { name: "Rest APIs, Git, AWS, JIRA, ITIL V4", value: 65 },
    { name: "Site Reliability Engineering", value: 87},
    { name: "APIs and Webhook", value: 87},
    
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Leadership", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you wish to reach out to me, drop an email to",
  email: "contact@arunsoorya.com"
};

const experiences = {
  show: true,
  heading: "Experience",
  data: [
    {
      role: 'Technical Support Analyst, Zoho Corporation',// Here Add Company Name
      companylogo: require('../assets/img/zoho-logo.png'),
      imgSize: 170,
      date: 'July 2020 – September 2022',
    },
    {
      role: 'Customer Support Analyst, Squadcast Labs',
      companylogo: require('../assets/img/squadcast-logo.png'),
      imgSize: 170,
      date: 'September 2022 – Present',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };